import { AppConfig } from "../config/app.config";
import { GeoData } from "../interfaces/geo-data.interface";
import { IGeolocationProvider } from "../interfaces/geolocation-provider.interface";
import { IStorageProvider } from "../interfaces/storage-provider.interface";

declare global {
  const ymaps: any;
}

export class GeolocationProvider implements IGeolocationProvider {
  constructor(private readonly storageProvider: IStorageProvider) {}

  private geoStorage = this.storageProvider.createContainer("geo");

  public async getGeoData(): Promise<GeoData> {
    const KEY = "geoData";
    let geoData = this.geoStorage.get<GeoData>(KEY);
    
    if (!geoData) {
      geoData = await this.fetchGeoData();
      this.geoStorage.set(KEY, geoData).setExpiry(24).save();
    }

    return geoData;
  }

  private async fetchGeoData(): Promise<GeoData> {
    const script = document.createElement("script");
    script.src = "https://api-maps.yandex.ru/2.0/?load=package.full&lang=ru-RU";
    document.head.appendChild(script);

    const ymapsGeo:any = await new Promise(resolve => {
      script.onload = function () {
        ymaps.ready(() => {
          resolve(ymaps.geolocation);
        })
      };
    });

    return {
      city: ymapsGeo.city,
      region: ymapsGeo.region,
      country: ymapsGeo.country
    }
  }
}