import { IStorageContainer } from '../interfaces/storage-container.interface';
import { IStorageProvider } from '../interfaces/storage-provider.interface';
import { IStorage } from '../interfaces/storage.interface';

export class StorageContainer implements IStorageContainer {
  constructor(
    public readonly name: string,
    private storage: Record<string, any> = {},
    private readonly storageProdiver: IStorageProvider
  ) {}

  private expiry: number;

  public set(key: string, data: Record<string, any>) {
    this.storage[key] = data;
    return this;
  }

  public get<T extends Record<string, any>>(key: string): T {
    return this.storage[key];
  }

  public save() {
    this.storageProdiver.save(this.name, this.storage, this.expiry);
  }

  public setExpiry(hours: number) {
    this.expiry = hours;
    return this;
  }

  public isExpired() {
    return this.storageProdiver.isExpired(this.storage);
  }
}
