import { AppConfig, appConfig } from "./config/app.config";
import { OptionsDto } from "./dto/options.dto";
import { QuizEvent } from "./enums/quiz-event.enum";
import { IOptions } from "./interfaces/options.interface";
import { EventEmitter } from "./components/event-emitter";
import { HttpProvider } from "./providers/http.provider";
import { IFrame } from "./components/iframe";
import { PostMessageProvider } from "./providers/post-message.provider";
import { Quiz } from "./components/quiz";
import { QuizState } from "./components/quiz-state";
import { StoreProvider } from "./providers/store.provider";
import { StorageProvider } from "./providers/storage.provider";
import { YaMetrikaCounter } from "./components/ya-metrika";
import { validateObject } from "./utils/validate-object";
import { Analytics } from "./components/analytics";
import { GTag } from "./components/gtag";
import { GeolocationProvider } from "./providers/geolocation.prodiver";

declare global {
  interface Window {
    quizFactory: (options: IOptions) => Quiz
  }
}

const quizFactory = (options: IOptions) => { 
  const quizOptionsDto = new OptionsDto(options);
  validateObject(quizOptionsDto);
  
  const config = appConfig<AppConfig>({ quiz_host: options.host });
  const quizEventEmitter = new EventEmitter<QuizEvent>();
  const storeProvider = new StoreProvider(new QuizState());
  const iframe = new IFrame(config.quiz_host, options.mode, options.iframe);
  const postMessageProvider = new PostMessageProvider(iframe, quizEventEmitter, config, storeProvider);
  const storageProvider = new StorageProvider();
  const httpProvider = new HttpProvider(config);
  const geoLocationProvider = options.enableGeolocation ? new GeolocationProvider(storageProvider) : undefined;

  const analytics = new Analytics({
    plugins: [
      new YaMetrikaCounter(config.ya_metrika_counter),
      new GTag(config.gtag_id, config),
    ],
  });

  return new Quiz(
    quizOptionsDto,
    storeProvider,
    quizEventEmitter,
    postMessageProvider,
    storageProvider,
    iframe,
    analytics,
    httpProvider,
    geoLocationProvider
  );
}

window.quizFactory = quizFactory;