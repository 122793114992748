import { AppConfig } from "../config/app.config";
import { PostMessageDto } from "../dto/post-message.dto";
import { QuizEvent } from "../enums/quiz-event.enum";
import { IIframe } from "../interfaces/iframe.interface";
import { IPostMessage } from "../interfaces/post-message.interface";
import { validateObject } from "../utils/validate-object";
import { EventEmitter } from "../components/event-emitter";
import { IStoreProvider } from "../interfaces/store-provider.interface";
import { QuizState } from "../components/quiz-state";

export class PostMessageProvider {
  constructor(
    private readonly iframe: IIframe,
    private readonly eventEmitter: EventEmitter<QuizEvent>,
    private readonly config: AppConfig,
    private readonly storeProvider: IStoreProvider<QuizState>
  ) { this.subscribe() }

  private subscribe() {
    window.addEventListener("message", ({data}) => {
      let message: IPostMessage;
      if (typeof data == "string") {
        try {
          message = JSON.parse(data);
  
          validateObject(new PostMessageDto(message));
          this.eventEmitter.emit(message.event, message.payload);
        } catch(error) { 
          if (this.config.env == "development") console.warn(error);
        }
      }
    });
  }

  public async send(message: Pick<IPostMessage, "event" | "payload">) {
    const dto = {...message, access_key: this.config.post_message_access_key};
    return new Promise<void>((resolve, reject) => {
      try {
        validateObject(new PostMessageDto(dto));
        const sendPostMessage = () => {
          this.iframe.window.postMessage(JSON.stringify(dto), this.config.quiz_host);
          resolve();
        }
  
        if (this.storeProvider.getState().wasLoaded) {
          sendPostMessage();
        } else {
          this.eventEmitter.subscribe(QuizEvent.LOAD, () => sendPostMessage());
        }
  
      } catch (error) {
        reject();
        if (this.config.env == "development") {
          if (!(error instanceof SyntaxError)) throw new Error(error);
        }
      }
    });
  }
}