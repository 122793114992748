import { StorageContainer } from "../components/storage-container";
import { IStorageProvider } from "../interfaces/storage-provider.interface";
import { IStorage } from "../interfaces/storage.interface";

export class StorageProvider implements IStorageProvider {
	constructor(private readonly prefix: string = "dentolo_") {}

  public save(name:string, storage: IStorage, expiry: number = 1): void {
    const expiration = expiry ? this.defineExpiryDate(expiry) : null;
		if (this.isSupported()) {
			window.localStorage.setItem(this.prefix + name, JSON.stringify({...storage, expiration }));
		}
	}

  public createContainer(name: string) {
    let storage = this.get(name);

    if (this.isExpired(storage)) {
      window.localStorage.removeItem(name);
      storage = {};
    }

    return new StorageContainer(name, storage, this);
  }

	public isExpired(storage: IStorage): boolean {
    if (typeof storage?.expiration == 'number') {
      return storage.expiration < new Date().getTime();
    } else return true;
	}

	private isSupported() {
		try {
			const testKey = "__some_random_key_you_are_not_going_to_use__";
			window.localStorage.setItem(testKey, testKey);
			window.localStorage.removeItem(testKey);
			return true;
		} catch (e) {
			console.warn('localStorage is disabled');
			return false;
		}
	}

  private defineExpiryDate(hours:number):number {
    const MINUTES = 60;
    const SECONDS = 60;
    const MILLISECONDS = 1000;
    const currentDate:number = new Date().getTime();
    return currentDate + hours * MINUTES * SECONDS * MILLISECONDS;
  }
  
  private get(name: string) {
    if (!this.isSupported()) return {};

    try {
      const item = JSON.parse(window.localStorage.getItem(this.prefix + name));
      return item || {};
    } catch(e) { return {} };
  }
}