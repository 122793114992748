import { AppConfig } from "../config/app.config";
import { IHttpProvider } from "../interfaces/http-provider.interface";

export class HttpProvider implements IHttpProvider {
  constructor(private readonly config: AppConfig) {}

  private async fetch(url: string, requestInit?:RequestInit, attempts?: number): Promise<Response> {
    const retry = (e: any) => {
      return new Promise<Response>((resolve) => {
        setTimeout(() => {
          if (attempts === 1) throw new Error(e);
          this.fetch(url, requestInit, attempts - 1).then(response => resolve(response));
        }, 1000);
      });
    }

    try {
      return await fetch(url, requestInit);
    } catch (e) {
      console.log(e);
      return retry(e);
    }
  }

  public async get<T>(url: string, attempts: number = 10): Promise<T> {
    const response = await this.fetch(this.config.api_host + url, { method: "GET" }, attempts);
    return await response.json();
  }

  public post<T>(url: string, data: T): Promise<T> { return; }

  public patch<T>(url: string, data: Partial<T>): Promise<T> { return; }

  public delete(url: string): void {}

}