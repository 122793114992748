import { IAnalyticsPlugin } from "../interfaces/analytics-plugin.interface";
import gtag, { install } from "ga-gtag";
import { AppConfig } from "../config/app.config";
import { IABTest } from "../interfaces/abtest.interface";

export class GTag implements IAnalyticsPlugin {
  constructor(
    private readonly track_number: string,
    private readonly config: AppConfig
  ) {}

  public init() {
    install(this.track_number, {
      debug_mode: this.config.env == "development"
    });
  }

  public track(event: string, params) {
    gtag("event", event, params);
  }

  public setParams(params: Record<string, any>) {
    gtag("event", "params", params);
  }

  public setABTest(abTest: IABTest) {
    gtag("event", "ABTest", {
      event_category: abTest.test_name,
      event_action: abTest.test_variant
    });
  }
}