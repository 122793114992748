import { validateSync, ValidationError } from "class-validator";

export function validateObject(target: Record<string, any>): void {
  const errors = validateSync(target);
  errors.forEach(error => {
    if (error.constraints) throw new Error(formatErrorMessage(error));
    
    if (error.children) error.children.forEach(child => {
      throw new Error(formatErrorMessage(child))
    })
  });
}

function formatErrorMessage(error:ValidationError) {
  return Object.values(error.constraints)
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map(message => {
      if (/^\d\..+$/.test(message)) return message.substr(2);
      return message;
    })
    .join('. ')
}