import { QuizEvent } from "../enums/quiz-event.enum";
import { QuizStatus } from "../enums/quiz-status.enum";
import { IIframe } from "../interfaces/iframe.interface";
import { IOptions } from "../interfaces/options.interface";
import { IQuiz } from "../interfaces/quiz.interface";
import { IStorageProvider } from "../interfaces/storage-provider.interface";
import { getQueryParams } from "../utils/get-query-params";
import { EventEmitter } from "./event-emitter";
import { PostMessageProvider } from "../providers/post-message.provider";
import { QuizState } from "./quiz-state";
import { IStoreProvider } from '../interfaces/store-provider.interface';
import { IWidget } from '../interfaces/widget.interface';
import { QuizMode } from '../enums/quiz-mode.enum';
import { ReachGoalData } from '../interfaces/reach-goal-data.interface';
import { OpenTrigger } from '../enums/open-trigger.enum';
import { IHttpProvider } from '../interfaces/http-provider.interface';
import { getCookie } from "../utils/get-cookie";
import { IAnalytics } from "../interfaces/analytics.interface";
import { IGeolocationProvider } from "../interfaces/geolocation-provider.interface";
import { IABTest } from "../interfaces/abtest.interface";
import { WidgetEvent } from "../enums/widget-event.enum";
import { IFixBlockOptions } from "../fix-block/interfaces/fix-block-options.interface";

export class Quiz implements IQuiz {
  constructor(
    private readonly options: IOptions,
    private readonly storeProvider: IStoreProvider<QuizState>,
    private readonly quizEventEmitter: EventEmitter<QuizEvent>,
    private readonly postMessageProvider: PostMessageProvider,
    private readonly storageProvider: IStorageProvider,
    private readonly iframe: IIframe,
    private readonly analytics: IAnalytics,
    private readonly httpProvider: IHttpProvider,
    private readonly geoLocationProvider?: IGeolocationProvider
  ) {}

  private popupStorage = this.storageProvider.createContainer("autopopup");

  public async init(): Promise<void> {
    this.storeProvider.updateState({ status: QuizStatus.INITIALIZING });

    const available = await this.checkAvailability();

    if (!available) {
      this.storeProvider.updateState({ status: QuizStatus.NOT_AVAILABLE });
      throw new Error("Quiz is not available");
    }

    this.quizEventEmitter.subscribe<void>(QuizEvent.LOAD, () => this.onLoad());
    this.quizEventEmitter.subscribe<ReachGoalData>(
      QuizEvent.REACH_GOAL,
      (data) => this.analytics.track(data.event, data.params)
    );

    this.quizEventEmitter.subscribe(QuizEvent.SET_PARAMS, params => this.analytics.setParams(params));
    this.quizEventEmitter.subscribe(QuizEvent.SET_AB_TEST, (abTest: IABTest) => this.analytics.setABTest(abTest));

    this.iframe.init();
    this.iframe.loadContent();

    if (this.options.mode == QuizMode.CUSTOM) {
      this.open(OpenTrigger.QUIZ_MODE_CUSTOM);
    }

    if (this.options.mode == QuizMode.FULL) {
      this.open(OpenTrigger.QUIZ_MODE_FULL);
      this.addMeta();
    }

    if (this.options.mode == QuizMode.POPUP) {
      if (this.options.autoOpen) this.autoOpen();
      this.quizEventEmitter.subscribe(QuizEvent.CLICK_CROSS, () => this.close());
    }

    if (this.options.openOnScroll && this.popupStorage.isExpired()) {
      window.addEventListener("scroll", () => this.onScroll());
    }

    if (this.options.openOnLeave && this.popupStorage.isExpired()) {
      document.addEventListener("mouseleave", (e) => this.onLeave(e));
    }

    this.quizEventEmitter.emit(QuizEvent.INIT);
    this.storeProvider.updateState({ initialized: true, status: QuizStatus.CLOSED });
  }

  public initialized() {
    return this.storeProvider.getState().initialized;
  }

  public async open(trigger: OpenTrigger = OpenTrigger.OTHER) {
    // Если плагин в процессе инициализации, то ожидаем
    if (this.storeProvider.getState().status == QuizStatus.INITIALIZING) {
      await new Promise<void>(resolve => this.quizEventEmitter.subscribe(QuizEvent.INIT, () => resolve()));
    }

    if (!this.storeProvider.getState().initialized) throw new Error("Необходима инициализация");

    if (this.storeProvider.getState().status == QuizStatus.OPENED) return;
    this.iframe.show();

    const openAction = (resolve: () => void) => {
      this.popupStorage.setExpiry(this.options.autoOpenLimit).save();
      this.postMessageProvider.send({
        event: QuizEvent.OPEN,
        payload: { trigger },
      });
      this.storeProvider.updateState({
        status: QuizStatus.OPENED,
        wasOpened: true,
      });
      this.quizEventEmitter.emit(QuizEvent.OPEN);
      resolve();
    };
    
    return new Promise<void>(resolve => {
      if (this.storeProvider.getState().wasLoaded) return openAction(resolve);
        else this.quizEventEmitter.subscribe(QuizEvent.LOAD, () => openAction(resolve)).unsubscribeOnEmit();
    });
  }

  public close() {
    if (this.storeProvider.getState().status == QuizStatus.OPENED) {
      this.storeProvider.updateState({
        status: QuizStatus.CLOSED,
        wasClosed: true,
      });
      this.iframe.hide();
      this.quizEventEmitter.emit(QuizEvent.CLOSE);
    }
  }

  public bind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.subscribe(event, callback);
  }

  public unbind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.unsubscribe(event, callback);
  }

  public setAutoOpen(seconds?: number) {
    return this.autoOpen(seconds);
  }

  public sendLeadExtraParams(params: Record<string, any>) {
    return this.postMessageProvider.send({
      event: QuizEvent.SEND_EXTRA_PARAMS,
      payload: params
    });
  }

  public setHeaderPhone(phone: string) {
    return this.postMessageProvider.send({ 
      event: QuizEvent.SET_HEADER_PHONE, 
      payload: { phone }
    });
  }

  public async addFixBlock(options: IFixBlockOptions): Promise<IWidget> {
    const { fixBlockFactory } = await import('../fix-block/fix-block-factory');
    const fixblock = fixBlockFactory(options);
    return this.connectWidget(fixblock);
  }

  public enableWebvisor(counter: number) {
    return this.postMessageProvider.send({
      event: QuizEvent.ENABLE_WEBVISOR,
      payload: { counter, domain: window.location.hostname }
    });
  }

  private autoOpen(seconds: number = this.options.autoOpen) {
    const { autoOpenTimeoutId } = this.storeProvider.getState();
    if (autoOpenTimeoutId) clearTimeout(autoOpenTimeoutId);

    const timeoutId = setTimeout(() => {
      const { wasOpened, status } = this.storeProvider.getState();
      if (!wasOpened && status == QuizStatus.CLOSED && this.popupStorage.isExpired()) {
        this.open(OpenTrigger.AUTO_OPEN);
      }
    }, seconds * 1000);

    this.storeProvider.updateState({
      autoOpenTimeoutId: timeoutId
    });
  }

  private async checkAvailability(): Promise<boolean> {
    const { active } = await this.httpProvider.get<{ active: boolean }>(`/quizzes/${this.options.id}/availability`);
    return active;
  }

  private async onLoad() {
    const geolocation = await this.geoLocationProvider?.getGeoData();

    this.storeProvider.updateState({ wasLoaded: true });
    this.postMessageProvider.send({
      event: QuizEvent.SET_PLUGIN_DATA,
      payload: {
        quizId: this.options.id,
        mode: this.options.mode,
        extraOptions: this.options.extraOptions,
        extra: {
          ...getQueryParams(),
          ...this.options.leadExtraParams,
          domain: document.domain,
          href: window.location.href,
          geolocation: geolocation,
          cookies: {
            _ga: getCookie("_ga"),
            _fbp: getCookie("_fbp"),
            _ym_uid: getCookie("_ym_uid"),
            roistat_visit: getCookie("roistat_visit"),
            roistat_first_visit: getCookie("roistat_first_visit"),
            roistat_visit_cookie_expire: getCookie(
              "roistat_visit_cookie_expire"
            ),
          },
        },
      },
    });
  }

  private onScroll(): void {
    const { pageYOffset, innerHeight } = window;

    if (this.storeProvider.getState().wasOpened) {
      return window.removeEventListener("scroll", this.onScroll);
    }

    if (pageYOffset + innerHeight * 1.1 >= document.body.offsetHeight) {
      this.open(OpenTrigger.SCROLL);
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  private onLeave(event: MouseEvent): void {
    if (this.storeProvider.getState().wasOpened) {
      return document.removeEventListener("mouseleave", this.onLeave);
    }

    if (event.clientY < 0) {
      this.open(OpenTrigger.LEAVE);
      document.removeEventListener("mouseleave", this.onLeave);
    }
  }

  private addMeta() {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
    document.head.appendChild(meta);
  }

  private async connectWidget(widget: IWidget): Promise<IWidget> {
    await widget.init();
    widget.show();
    widget.bind(WidgetEvent.CALL_QUIZ, () => this.open(widget.name));
    this.quizEventEmitter.subscribe(QuizEvent.GET_LEAD, () => widget.close());

    return widget;
  }
}