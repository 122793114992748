import { clearObject } from "../utils/clear-object";

export const appConfig = <T extends {} = any>(args?: Partial<T>) => ({
    env: process.env.NODE_ENV,
    quiz_host: process.env.QUIZ_HOST,
    api_host: process.env.API_HOST,
    post_message_access_key: process.env.POST_MESSAGE_ACCESS_KEY,
    ya_metrika_counter: Number(process.env.YA_METRIKA_COUNTER),
    gtag_id: process.env.GTAG_ID,
    ...clearObject(args)
});

export type AppConfig = ReturnType<typeof appConfig>;