import { IABTest } from "../interfaces/abtest.interface";
import { IAnalyticsPlugin } from "../interfaces/analytics-plugin.interface";
import { IAnalytics } from "../interfaces/analytics.interface";

interface AnalyticsOptions {
  plugins: IAnalyticsPlugin[];
}

export class Analytics implements IAnalytics {
  constructor(options: AnalyticsOptions) {
    this.plugins = options.plugins;
    this.plugins.forEach(plugin => plugin.init());
  }

  private plugins: IAnalyticsPlugin[];

  public track(event: string, params?: Record<string, any>) {
    this.plugins.forEach(plugin => plugin.track(event, params));
  }

  public setParams(params: Record<string, any>) {
    this.plugins.forEach(plugin => plugin.setParams(params));
  }

  public setABTest(abTest: IABTest) {
    this.plugins.forEach(plugin => plugin.setABTest(abTest));
  }
}