import { IsBoolean, IsDefined, IsEnum, IsInt, IsObject, IsOptional, IsString, IsUrl, Max, Min, ValidateIf } from "class-validator";
import { QuizMode } from "../enums/quiz-mode.enum";
import { IOptions } from "../interfaces/options.interface";

export class OptionsDto implements IOptions {
  constructor(options: IOptions) {
    this.id = options.id;
    this.mode = options.mode;
    this.autoOpen = options.autoOpen;
    this.autoOpenLimit = options.autoOpen || this.autoOpenLimit;
    this.host = options.host;
    this.openOnScroll = options.openOnScroll || this.openOnScroll;
    this.openOnLeave = options.openOnLeave || this.openOnLeave;
    this.enableGeolocation = options.enableGeolocation || this.enableGeolocation;
    this.iframe = options.iframe;
    this.extraOptions = options.extraOptions;
  }

  @IsDefined({ message: "1.Необходимо указать параметр $property" })
  @IsString({ message: "2.Параметр $property должен быть строкой" })
  readonly id: string;

  @IsDefined()
  @IsEnum(QuizMode, { message: "Неверное значение для параметра $property" })
  readonly mode: QuizMode;

  @IsOptional()
  @IsInt({ message: "1.Параметр $property должен быть числом" })
  @Min(0, { message: "2.Параметр $property должен быть равен или больше 0" })
  @Max(600, { message: "3.Время автооткрытия должно быть меньше 10 минут" })
  readonly autoOpen?: number;

  @IsOptional()
  @IsInt({ message: "1.Параметр $property должен быть числом" })
  @Min(1, { message: "2.Параметр $property должен быть равен или больше 1" })
  @Max(24*7*30, { message: "3.Лимит автооткрытия должен быть не более 30 дней" })
  readonly autoOpenLimit?: number = 1;

  @IsOptional()
  @IsBoolean()
  readonly openOnScroll: boolean = false;

  @IsOptional()
  @IsBoolean()
  readonly openOnLeave: boolean = true;

  @ValidateIf((o: OptionsDto) => !o.host?.includes("localhost"))
  @IsOptional()
  @IsUrl({}, { message: "Параметр $property должен быть url адресом" })
  readonly host?: string;

  @IsOptional()
  @IsObject()
  readonly customParams?: Record<string, any>;

  @IsOptional()
  @IsBoolean()
  readonly enableGeolocation: boolean = false;

  @ValidateIf((o: OptionsDto) => o.mode == QuizMode.CUSTOM)
  @IsString()
  readonly iframe?: string;
  
  @IsOptional()
  @IsBoolean()
  readonly extraOptions?: Record<string, any>;

}